<template>
  <div>
    <b-row>
      <b-col>
        <h2>Document Report Templates</h2>
      </b-col>
    </b-row>
    <hr>
    <b-row>
      <b-col>
        <b-button
          type="button"
          variant="outline-primary"
          class="w-100"
          @click="chooseFile()"
        >
          <span>Choose File</span>
        </b-button>
      </b-col>
      <b-col>
        <span>{{ SelectedFile ? SelectedFile.FileName : "No File Chosen" }}</span>
      </b-col>
      <b-col>
        <b-button
          type="button"
          variant="primary"
          @click="uploadFile()"
        >
          <span>Upload</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <!-- table -->
      <b-col
        class="mt-2 min-height-10 rounded-sm white-bg-container"
        md="10"
      >
        <b-row class="pb-1">
          <b-col md="5">
            Template
          </b-col>
          <b-col md="3">
            File Size
          </b-col>
          <b-col md="3">
            Upload Date
          </b-col>
          <b-col md="1">
            Action
          </b-col>
        </b-row>
        <b-row
          v-for="(item, index) of Templates"
          :key="index"
        >
          <b-col>
            <b-row class="pt-1">
              <b-col md="5">
                <span
                  class="text-nowrap cursor-pointer"
                  @click="downloadFile(item)"
                >{{ item.FileName }}</span>
              </b-col>
              <b-col md="3">
                <span>{{ item.FileSize }}</span>
              </b-col>
              <b-col md="3">
                <span>{{ item.CreatedAt | dateFormat }}</span>
              </b-col>
              <b-col md="1">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item>
                      <span @click="deleteFile(item)">Delete</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal
      id="modal-delete-file"
      ok-only
      ok-variant="danger"
      :ok-title="'Delete ' + fileToDelete.FileName"
      modal-class="modal-danger"
      centered
      title="Confirm Delete Permission Set"
      @ok="confirmDeleteFile()"
    >
      <div v-if="fileToDelete !== null">
        Are you sure you want to delete {{ fileToDelete.FileName }}?
      </div>
    </b-modal>
  </div>
</template>

<script>
import {BButton, BCol, BDropdown, BDropdownItem, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";

const apiService = new APIService();

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      if (date) return date.toLocaleDateString("en-US", options);
    },
  },
  data() {
    return {
      Templates: [],
      SelectedFile: {},

      fileToDelete: {},
    }
  },
  created() {
    this.getTemplates();
  },
  methods: {
    getTemplates() {
      apiService
          .get("template/case-report-templates")
          .then(res => {
            this.Templates = res.data;
          })
    },
    chooseFile() {
      const input = document.createElement('input');
      input.type = 'file';
      input.onchange = e => {
        const file = e.target.files[0];
        this.SelectedFile = {
          FileName: file.name,
          FileSize: file.size,
          CreatedAt: file.lastModified,
          file: file,
        };
      }
      input.click();
    },
    uploadFile() {
      let formData = new FormData();
      formData.append("FormFile", this.SelectedFile.file);
      apiService
          .post("template/case-report-template/create", formData, {'Content-Type': 'multipart/form-data'})
          .then(() => {
            this.SelectedFile = {};
            this.getTemplates();
          })
          .catch(res => {
            console.log(res);
          });
    },
    downloadFile(file) {
      const link = document.createElement('a');
      link.href = '/api/file/stream/' + file.FileID;
      link.setAttribute('download', file.FileName);
      document.body.appendChild(link);
      link.click();
    },
    deleteFile(File) {
      this.fileToDelete = File;
      this.$bvModal.show('modal-delete-file');
    },
    confirmDeleteFile() {
      apiService
          .delete("file/" + this.fileToDelete.FileID)
          .then(() => {
            this.fileToDelete = {};
            this.getTemplates();
          })
    },
  },
}
</script>

<style scoped>

</style>